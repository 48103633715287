/**
 * Monkey patch for browser objects not available in node runtime.
 */

export const isServerSide = typeof window === 'undefined';

if (isServerSide) {
  // HACK: For node_modules/@emotion/react/dist/emotion-react.browser.esm.js
  // prevents attempting to access the `window` element.
  global.jest = {};

  // HACK: Package `remark-parse` calls `decode-named-character-reference`
  // which tried to access the `document` global which is not available when
  // server-side rendering in node. This import makes a global `document` object
  // available which monkey-patches this issue.
  global.document = {
    createElement: el => undefined,
    getElementsByTagName: tag => [undefined],
    querySelector: target => undefined,
    getElementById: id => undefined,
  };
}

export const document = global.document;
