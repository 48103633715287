import { getBootstrapData } from './bootstrapData';

const bootstrapData = getBootstrapData();

export const USER_ID = bootstrapData?.user?.userId;
export const USER_FIRST_NAME = bootstrapData?.user?.firstName;
export const USER_LAST_NAME = bootstrapData?.user?.lastName;
export const USER_FULL_NAME =
  USER_ID && `${USER_FIRST_NAME} ${USER_LAST_NAME}`.trim();

export const IS_ADMIN = !!bootstrapData?.user?.roles?.Admin;
export const IS_ALPHA = !!bootstrapData?.user?.roles?.Alpha;
export const IS_GAMMA = !!bootstrapData?.user?.roles?.Gamma;
export const IS_PUBLIC = !!bootstrapData?.user?.roles?.Public;

const DATASOURCE_ACCESS_IDS = new Set(
  Object.values(bootstrapData?.user?.roles || {})
    .flat()
    .filter(p => p[0] === 'datasource_access')
    .map(p => Number(p[1].split('id:').slice(-1)[0].slice(0, -1))),
);

const DATASOURCE_QUERY_IDS = new Set(
  Object.values(bootstrapData?.user?.roles || {})
    .flat()
    .filter(p => p[0] === 'datasource_query')
    .map(p => Number(p[1].split('id:').slice(-1)[0].slice(0, -1))),
);

const HAS_ALL_DATASOURCE_ACCESS = !!Object.values(
  bootstrapData?.user?.roles || {},
)
  .flat()
  .find(p => p?.[0] === 'all_datasource_access');

export const hasTableAccess = id =>
  IS_ADMIN || HAS_ALL_DATASOURCE_ACCESS || DATASOURCE_ACCESS_IDS.has(id);

export const hasSomeTablesAccess = tables =>
  tables.length === 0 || tables.map(t => hasTableAccess(t.id)).some(b => b);

export const hasAllTablesAccess = tables =>
  tables.length === 0 || tables.map(t => hasTableAccess(t.id)).all(b => b);

export const hasTableQuery = id =>
  IS_ADMIN ||
  IS_ALPHA ||
  HAS_ALL_DATASOURCE_ACCESS ||
  DATASOURCE_QUERY_IDS.has(id);

export const hasSomeTablesQuery = tables =>
  tables.length === 0 || tables.map(t => hasTableQuery(t.id)).some(b => b);

export const hasAllTablesQuery = tables =>
  tables.length === 0 || tables.map(t => hasTableQuery(t.id)).all(b => b);

export const isCreator = obj => !!USER_ID && obj?.created_by?.id === USER_ID;

export const isOwner = obj =>
  !!USER_ID && obj?.owners?.map(o => o.id).includes(USER_ID);

export const isCreatorOrOwner = obj => isCreator(obj) || isOwner(obj);
