/**
 * General site layout.
 */

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Layout as AntdLayout, Menu, Tooltip, Button, Typography } from 'antd';
import { SettingFilled, UserOutlined } from '@ant-design/icons';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import CookieNotice from './CookieNotice';
import TermsOfUseModal from './TermsOfUseModal';
import { isServerSide } from '../utils/serverSide';
import { USER_ID, USER_FULL_NAME } from '../utils/privileges';

import './Layout.css';

const { Header, Content, Sider, Footer } = AntdLayout;
const { Link } = Typography;

const windowVar = isServerSide ? {} : window;

const headerHeight = 64;
const siderWidth = 280;

// We are on a website page (e.g. splash, services, journal, about, contact)
// if we are server-side rendered or we are at a known endpoint.
const pathname = isServerSide
  ? process.env.PATHNAME
  : windowVar.location.pathname;
const isWebsitePage =
  pathname === '/' ||
  [
    '/services',
    '/technology',
    '/markets',
    '/journal',
    '/case-studies',
    '/about',
    '/contact',
  ].some(path => pathname?.startsWith(path)) ||
  (!pathname && isServerSide);

const Layout = ({
  siderContent,
  mainContent,
  sider = true,
  breadcrumb,
  selectedKeys,
  termsModal = false,
  cookieNotice = true,
  dark = false,
}) => {
  // Use a hook to detect scroll height and manage sider height
  const [headerShim, setHeaderShim] = useState(undefined);
  useScrollPosition(({ currPos }) => {
    const newHeaderShim = Math.max(0, headerHeight + currPos.y);
    if (headerShim !== newHeaderShim) {
      setHeaderShim(newHeaderShim);
    }
  });

  return (
    <>
      {termsModal ? <TermsOfUseModal /> : null}
      {cookieNotice ? <CookieNotice /> : null}
      <AntdLayout>
        <Header className="header">
          <div>
            <div
              style={{
                float: 'left',
                height: '100%',
                width: '188px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography.Link href="/">
                <img
                  alt="Technotia Logo"
                  src="static/images/technotia-logo-email.png"
                  id="tentacle-header-logo"
                />
              </Typography.Link>
            </div>
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={selectedKeys}
            forceSubMenuRender
            style={{ overflow: 'auto' }} // needed for submenu on small width
          >
            {/* Main menu items */}
            {isWebsitePage ? (
              // Website menu
              <>
                <Menu.Item className="module-button" key="technology">
                  <Typography.Link href="/technology">
                    Technology
                  </Typography.Link>
                </Menu.Item>
                <Menu.Item className="module-button" key="markets">
                  <Typography.Link href="/markets">Markets</Typography.Link>
                </Menu.Item>
                <Menu.Item className="module-button" key="case-studies">
                  <Typography.Link href="/case-studies">
                    Case Studies
                  </Typography.Link>
                </Menu.Item>
                <Menu.Item className="module-button" key="contact">
                  <Typography.Link href="/contact">Contact</Typography.Link>
                </Menu.Item>
              </>
            ) : (
              // Platform menu
              <>
                <Menu.Item className="module-button" key="datasets">
                  <Typography.Link href="/datasets">Datasets</Typography.Link>
                </Menu.Item>
                <Menu.Item className="module-button" key="charts">
                  <Typography.Link href="/charts">Charts</Typography.Link>
                </Menu.Item>
                <Menu.Item className="module-button" key="dashboards">
                  <Typography.Link href="/dashboards">
                    Dashboards
                  </Typography.Link>
                </Menu.Item>
                <Menu.Item className="module-button" key="explore">
                  <Typography.Link href="/charts/explore">
                    Explore
                  </Typography.Link>
                </Menu.Item>
              </>
            )}
            {/* Float right menu */}
            {isWebsitePage ? null : !USER_ID ? (
              // Not logged in
              <Menu.Item
                className="float-button"
                key="login"
                style={{ float: 'right' }}
              >
                <Button
                  type="primary"
                  href="/auth/login"
                  style={{ color: 'white' }}
                >
                  Login
                </Button>
              </Menu.Item>
            ) : (
              // Logged in
              <>
                <Menu.Item
                  style={{ float: 'right' }}
                  className="float-button"
                  key="logout"
                >
                  <Button
                    type="primary"
                    href="/auth/logout"
                    style={{ color: 'white' }}
                  >
                    Logout
                  </Button>
                </Menu.Item>
                <Menu.Item
                  style={{ float: 'right', padding: 0 }}
                  className="float-button"
                  key="settings"
                >
                  <Typography.Link href="/auth/settings">
                    <UserOutlined />
                    &nbsp;{USER_FULL_NAME}
                  </Typography.Link>
                </Menu.Item>
              </>
            )}
          </Menu>
        </Header>
        <AntdLayout className="tentacle-sider-layout">
          {sider ? (
            <Sider
              width={siderWidth}
              className="tentacle-sider"
              breakpoint="md" // 768px
              collapsedWidth="0"
              theme="light"
              style={{
                height: `calc(100vh - ${headerShim ?? headerHeight}px)`,
              }}
            >
              {siderContent}
            </Sider>
          ) : null}
          <AntdLayout
            className="tentacle-main-content-layout"
            style={dark ? { background: 'black' } : undefined}
          >
            {breadcrumb ? (
              <div style={{ paddingBottom: '12px' }}>{breadcrumb}</div>
            ) : null}
            <Content className="tentacle-main-content">{mainContent}</Content>
            <Footer
              id="tentacle-footer"
              className={dark ? 'tentacle-dark' : undefined}
              style={dark ? { background: 'black' } : undefined}
            >
              {!isWebsitePage ? null : (
                <div
                  style={{
                    textAlign: 'center',
                    margin: 'auto',
                    padding: '12px 0',
                    fontSize: '11px',
                    textTransform: 'uppercase',
                    letterSpacing: '4px',
                    fontWeight: 500,
                  }}
                >
                  <Link href="/">Home</Link> &middot;{' '}
                  <Link href="/technology">Technology</Link> &middot;{' '}
                  <Link href="/markets">Markets</Link> &middot;{' '}
                  <Link href="/case-studies">Case Studies</Link> &middot;{' '}
                  <Link href="/contact">Contact</Link>
                </div>
              )}
              <div className="tentacle-footer-copyright">
                Copyright &copy; {new Date().getFullYear()} Technotia Pty Ltd.
                All rights reserved. You acknowledge and agree, unreservedly, to
                the Terms of Use for this application, and agree that predicting
                the future is an inexact science and therefore the results, data
                and outputs are not advice or recommendations, that past
                performance is not an indicator of future performance and that
                it is your sole responsibility on how you interpret and decide
                to use this information. You should always seek independent
                professional advice, including financial advice that considers
                your individual situation.
              </div>
              <div className="tentacle-footer-link">
                <Link href="/legal/terms-of-use">Terms of Use</Link>
                <Link href="/legal/privacy-policy">Privacy Policy</Link>
              </div>
              <div style={{ margin: 'auto', marginTop: '8px' }}>
                — Build{' '}
                {isServerSide ? process.env.VERSION : window.__version__} —
              </div>
            </Footer>
          </AntdLayout>
        </AntdLayout>
      </AntdLayout>
    </>
  );
};

export default Layout;

export const attachToRoot = Component => {
  // Remove container used for pre-rendering HTML skeleton content on page load
  document.getElementById('pre-render-content')?.remove();
  const root = document.getElementById('app');
  ReactDOM.render(Component, root);
};

export const hydrateToRoot = Component => {
  // Remove container used for pre-rendering HTML skeleton content on page load
  document.getElementById('pre-render-content')?.remove();
  const root = document.getElementById('app');
  ReactDOM.hydrate(Component, root);
};
