import React, { useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';

const isServerSideRendered = !(typeof window !== 'undefined');
const windowVar = typeof window !== 'undefined' ? window : {};

const cookiesAcceptedKey = `tentacle_cookies_accepted_${windowVar?.location?.host}`;

const CookieNotice = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(true);
  useEffect(() => {
    // Set if cookies accepted after first render to allow server/client hydration match
    if (isServerSideRendered) return;
    if (!windowVar?.localStorage?.[cookiesAcceptedKey]) {
      setCookiesAccepted(false);
    }
  }, []);
  return (
    <Drawer
      placement="bottom"
      style={{ height: 0 }}
      maskStyle={{ height: 0 }}
      closable={false}
      maskClosable
      onClose={() => setCookiesAccepted(true)}
      visible={!cookiesAccepted}
      // extra={undefined}
      height="auto"
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: '16px',
          justifyContent: 'space-between',
        }}
      >
        <div>
          This website uses cookies to track your session and anonymously
          monitor traffic. Please click Accept to consent to our use of cookies.
        </div>
        <Button
          onClick={() => {
            windowVar.localStorage[cookiesAcceptedKey] = true;
            setCookiesAccepted(true);
          }}
        >
          Accept
        </Button>
      </div>
    </Drawer>
  );
};

export default CookieNotice;
