import { isServerSide } from './serverSide.js';

export const getBootstrapData = () => {
  if (isServerSide) {
    const dataBootstrap = process.env.BOOTSTRAP_DATA;
    return dataBootstrap && JSON.parse(dataBootstrap);
  } else {
    const appContainer = document.getElementById('app');
    const dataBootstrap = appContainer?.getAttribute('data-bootstrap');
    return dataBootstrap && JSON.parse(dataBootstrap);
  }
};
