import React, { useEffect, useRef, useState } from 'react';
import { Modal, Typography } from 'antd';

const { Title, Paragraph, Link } = Typography;

const isServerSideRendered = !(typeof window !== 'undefined');
const windowVar = isServerSideRendered ? {} : window;

const termsAcceptedKey = `tentacle_terms_accepted_${windowVar?.location?.host}`;

export const OrganisationTermsOfUse = () => (
  <>
    <Paragraph>
      There are Terms of Use, the Agreed Terms, with this application that your
      organisation has accepted. Please request a copy of these from your
      internal commercial team to review.
    </Paragraph>
    <Paragraph>
      By ticking "Yes, I accept" you acknowledge that your organisation has
      agreed to the Terms of Use.
    </Paragraph>
    <Paragraph>
      On this basis please be aware that predicting the future is an inexact
      science and therefore the results, data and outputs are not advice or
      recommendations, that past performance is not an indicator of future
      performance and that it is your sole responsibility on how you interpret
      and decide to use this information. You should always seek company
      approval and or independent professional advice, including financial
      advice that considers your individual situation.
    </Paragraph>
  </>
);

export const TechnotiaTermsOfUse = () => (
  <>
    <Title level={2}>Terms of Use</Title>
    <Paragraph>
      This Agreement made between Technotia Pty Ltd ACN 623 999 539 (
      <b>Technotia, we or us</b>) and the contracting party identified on the
      Subscription Order Form (<b>Customer, you</b>), or party accessing by way
      of public use, identifies the terms on which we provide you with access to
      the Application and services. If you are accessing the application as part
      of an organisational subscription, your company's agreed terms of use may
      supersede those in this document.
    </Paragraph>

    <Title level={3}>1. Agreement Structure</Title>

    <ol className="tentacle-terms-abc">
      <li>
        This Agreement consists of the following:
        <ol className="tentacle-terms-roman">
          <li>The Subscription Order Form</li>
          <li>The Terms of Use;</li>
          <li>The Schedules; and</li>
          <li>The Documentation (as updated from time to time),</li>
        </ol>
        (collectively the Agreement), and the earlier listed document will take
        precedence to the extent of any inconsistency.
      </li>
      <li>
        Where there is any inconsistency between Schedule 1 (Technotia Data
        Terms) and Schedule 2 (Technotia O&M Terms), Schedule 1 (Technotia Data
        Terms) will prevail.
      </li>
    </ol>

    <Title level={3}>2. Term</Title>
    <Paragraph>
      This Agreement commences on the Commencement Date and continues on a month
      to month basis, or as detailed in the Subscription Order Form, until
      terminated by either party in accordance with the terms of this Agreement.
    </Paragraph>

    <Title level={3}>3. Charges</Title>
    <ol className="tentacle-terms-abc">
      <li>
        Subject to the remainder of this clause 3, you will be automatically
        billed the charges as set out in the Subscription Order Form at the
        times specified in the Subscription Order Form
      </li>
      <li>
        <Paragraph>
          We may increase our Fees by providing you with 30 days written notice
          of the new Fee. If the Charges are not paid as per the terms:
        </Paragraph>
        <ol className="tentacle-terms-roman">
          <li>
            your subscription will be cancelled and Application access
            deactivated; and
          </li>
          <li>
            we may apply a service charge of 5% per month or the highest lawful
            interest rate (whichever is lower) to all amounts not paid to us
            when due.
          </li>
        </ol>
      </li>
      <li>
        The Charges are exclusive of taxes, and you will also pay applicable
        taxes and duties (including withholding taxes, goods and services tax
        (GST) or other taxes).
      </li>
    </ol>

    <Title level={3}>4. Access</Title>
    <ol className="tentacle-terms-abc">
      <li>
        We grant you a non-exclusive, non-transferable and revocable licence for
        the Term to access and use the Application and Documentation in
        accordance with this Agreement for your internal business purposes.
      </li>
      <li>
        We may update or modify the features or functionality of the Application
        to reflect developments in technology or to alter or add to existing
        functionality, including without notice.
      </li>
      <li>
        Updates, enhancements and new versions of the Application will become
        automatically available when you next access the Application.
      </li>
      <li>
        We will use reasonable endeavours to assist in resolving technical
        problems with the Application through email support during our business
        hours.
      </li>
    </ol>

    <Title level={3}>5. Changes to This Agreement</Title>
    <ol className="tentacle-terms-abc">
      <li>
        We are entitled to make changes to the provisions of the functionality
        of the Application or the Documentation from time to time. If those
        changes adversely materially impact you, we will give you 30 days’
        notice before the changes take effect, during which time you may
        terminate the Agreement.
      </li>
      <li>
        You agree that if you use the Application after the date on which such
        changes have occurred, we will treat your use of the Application as
        acceptance of the changed Agreement or functionality of the Application.
      </li>
    </ol>

    <Title level={3}>6. Your Obligations</Title>
    <ol className="tentacle-terms-abc">
      <li>
        <Paragraph>Your access and use of the Application:</Paragraph>
        <ol className="tentacle-terms-roman">
          <li>
            is limited to the number of Users set out in the applicable
            Subscription Order Form; and
          </li>
          <li>is subject to the Documentation.</li>
        </ol>
      </li>
      <li>
        <Paragraph>
          You are not permitted to use the Application for any purpose other
          than as outlined in clause 4(a) and specifically you must not use the
          Application:
        </Paragraph>
        <ol className="tentacle-terms-roman">
          <li>
            for the benefit of a third party other than your Affiliate; or
          </li>
          <li>to provide an outsourced or white label service.</li>
        </ol>
      </li>
      <li>
        You must not access or use the Application in a manner that introduces
        malicious programs into the Application or our hardware or software,
        including viruses, worms, trojan horses and e-mail bombs.
      </li>
      <li>
        You are fully responsible for the conduct of your Users and your
        Affiliates and must ensure that their access and use is in accordance
        with this Agreement.
      </li>
      <li>
        You are responsible for obtaining all computer hardware, software,
        network, internet, mobile telecommunications and any other necessary
        equipment required to access and use the Application.
      </li>
      <li>
        You must not modify, reproduce, revise, transmit, distribute, reverse
        engineer or alter the Application or Technotia Data.
      </li>
      <li>
        You must ensure that your access to and use of the Application or any
        outputs does not contravene any applicable laws and that you have all
        relevant approvals, licences and permissions relevant to the activities
        you are undertaking using the Application.
      </li>
      <li>
        We may track or monitor your access and use of the Application and you
        will provide reasonable support to allow this to occur.
      </li>
      <li>
        It is your responsibility to ensure you have appropriate backup measures
        in place because we are not responsible for loss, delay, interception or
        corruption in relation to Customer Data, Technotia Data, Application
        Output or your inability to access the Application.
      </li>
      <li>
        You are solely responsible for the accuracy, quality, integrity,
        legality, reliability, and appropriateness of the Customer Data.
      </li>
      <li>
        <Paragraph>You represent and warrant that:</Paragraph>
        <ol className="tentacle-terms-roman">
          <li>
            If you use the Application together with any Customer Data, you own
            the Customer Data or have the necessary licences, rights, consents
            and permissions to allow us to use the Customer Data in accordance
            with this Agreement;
          </li>
          <li>
            you have the right and power to grant us the licence contained in
            clause 7; and
          </li>
          <li>
            the Customer Data provided by you does not infringe the Intellectual
            Property Rights of any third party.
          </li>
        </ol>
      </li>
      <li>
        You indemnify us against all loss or damage incurred by us as a result
        of any third party claim that the Customer Data infringes the
        Intellectual Property Rights of any third party.
      </li>
      <li>
        If a claim of infringement occurs, or if you or we determine that a
        claim is likely to occur, you must at your own expense procure for us
        the right or licence to continue to use Customer Data free of the
        infringement claim.
      </li>
    </ol>

    <Title level={3}>7. Intellectual Property Rights</Title>
    <ol className="tentacle-terms-abc">
      <li>
        <Paragraph>
          You acknowledge and agree that we and our licensors are the owners of
          and will retain all rights, title and interest in all Intellectual
          Property Rights in the:
        </Paragraph>
        <ol className="tentacle-terms-roman">
          <li>Application;</li>
          <li>Documentation; and</li>
          <li>
            any improvements, enhancements or modifications to the Application
            or Documentation.
          </li>
        </ol>
      </li>
      <li>
        To the extent required, you assign all rights, title and interest in the
        Intellectual Property Rights outlined in clause 7(a) to us, with effect
        from the date of its creation.
      </li>
      <li>
        You grant us a non-exclusive, non-transferable, royalty free licence to
        use the Customer Data for the purpose of making the Application
        available, including making any improvements to the Application.
      </li>
    </ol>

    <Title level={3}>8. Confidentiality</Title>
    <Title level={4}>8.1 Obligations of confidentiality</Title>
    <Paragraph>Subject to clause 8.2, the Receiving Party must:</Paragraph>
    <ol className="tentacle-terms-abc">
      <li>
        keep the Confidential Information confidential and not disclose it
        without the prior written approval of the Disclosing Party;
      </li>
      <li>
        take all reasonable steps to secure and keep secure all Confidential
        Information coming into its possession or control;
      </li>
      <li>
        only use the Confidential Information for the purposes of performing,
        and to the extent necessary to perform, its obligations under this
        Agreement; and
      </li>
      <li>
        not memorise, modify, reverse engineer or make copies of the
        Confidential Information.
      </li>
    </ol>

    <Title level={4}>8.2 Exceptions</Title>
    <Paragraph>
      The obligations of confidentiality under clause 8.1 do not apply to:
    </Paragraph>
    <ol className="tentacle-terms-abc">
      <li>
        <Paragraph>any Confidential Information that:</Paragraph>
        <ol className="tentacle-terms-roman">
          <li>
            is disclosed to the Receiving Party by a third party entitled to do
            so, whether before or after the date of this Agreement;
          </li>
          <li>
            was already rightfully in the Receiving Party’s possession when it
            was given to the Receiving Party and was not otherwise acquired from
            the Disclosing Party directly or indirectly; or
          </li>
          <li>
            is generally available to the public at the date of this Agreement
            or subsequently becomes so available other than by reason of a
            breach of this Agreement; or
          </li>
        </ol>
      </li>
      <li>
        <Paragraph>
          any disclosure of Confidential Information by the Receiving Party that
          is necessary to comply with any applicable law if, to the extent
          practicable and as soon as reasonably possible, the Receiving Party:
        </Paragraph>
        <ol className="tentacle-terms-roman">
          <li>notifies the Disclosing Party of the proposed disclosure;</li>
          <li>consults with the Disclosing Party as to its content; and</li>
          <li>
            uses reasonable endeavours to comply with any reasonable request by
            the Disclosing Party concerning the proposed disclosure.
          </li>
        </ol>
      </li>
    </ol>

    <Title level={3}>9. Privacy and Data Protection</Title>
    <ol className="tentacle-terms-abc">
      <li>
        Each party will perform their obligations under this Agreement in
        accordance with their respective obligations under Privacy Laws.
      </li>
      <li>
        You must obtain all necessary consents from individuals whose Personal
        Information is included as part of the Customer Data to enable us to
        perform our obligations or exercise our rights under this Agreement.
      </li>
      <li>
        We will not be liable for a breach of clause 9(a) to the extent the
        breach is caused or contributed to by a failure by you to comply with
        your obligations under this clause 9.
      </li>
      <li>
        You indemnify us against all loss or damage incurred by us as a result
        of your breach of this clause 9.
      </li>
    </ol>

    <Title level={3}>10. Warranties and Disclaimer</Title>
    <ol className="tentacle-terms-abc">
      <li>
        You acknowledge and agree that access to and use of the Application and
        Documentation is provided on an “as is” basis and we make no warranties
        in relation to the availability, ongoing functionality, quality or
        accuracy of outputs or performance of the Application.
      </li>
      <li>
        The Application may not be continuously available or be error free
        including during periods of planned and unplanned maintenance.
      </li>
    </ol>

    <Title level={3}>11. Limitation of Liability</Title>
    <ol className="tentacle-terms-abc">
      <li>
        We exclude any liability to you or your Affiliates, whether in contract,
        tort (including negligence) or otherwise, for any special, indirect or
        consequential loss, loss of profits, loss of sales or business, loss of
        production, loss of agreements, loss of business opportunity, loss of
        anticipated savings, loss of or damage to goodwill, loss of reputation,
        and loss of use or corruption of software, data or information arising
        under or in connection with this Agreement.
      </li>
      <li>
        Subject to clause 11(a) and to the extent permitted by law, our
        aggregate liability in respect of any claims arising out of or in
        connection with this Agreement, whether in contract or tort (including
        negligence) or otherwise, will not, under any circumstances, exceed the
        Charges paid during the 6 months preceding the date on which the
        relevant cause of action arose.
      </li>
      <li>
        You acknowledge and agree that by providing or granting access to the
        Application, Technotia Data, and Application Output, we provide no
        advice or recommendations to you or your Affiliates and we are not in
        the business of providing advice of any kind. Notwithstanding anything
        else in this Agreement, you assume sole responsibility and entire risk
        as to the suitability and results obtained from use of the Application,
        Technotia Data, and associated services or the Application Output
        including any reports or outputs and we have no liability to you or your
        Affiliates for any decisions made or actions (including trades) or
        omissions taken based on your access or use of the Application,
        Technotia Data, Professional Services or Application Output (and you
        will hold us harmless from any liability to third parties including your
        Affiliates as a result of such use by you).
      </li>
      <li>
        Any representation, warranty, condition or undertaking that would be
        implied in this Agreement by legislation, common law, equity, trade,
        custom or usage is excluded to the maximum extent permitted by law, and
        we disclaim all other warranties (whether express, implied or statutory)
        and conditions, including fitness for purpose, merchantability or
        non-infringement.
      </li>
      <li>
        Nothing in this Agreement excludes, restricts or modifies any condition,
        warranty, right or remedy conferred by the Australian Consumer Law (as
        set out in Schedule 2 of the <i>Competition and Consumer Act 2010</i>{' '}
        (Cth)) or any other applicable law that cannot be excluded, restricted
        or modified by agreement.
      </li>
      <li>
        <Paragraph>
          To the fullest extent permitted by law, our liability for a breach of
          a non-excludable condition or warranty is limited at our option where
          permitted by the Australian Consumer Law (as set out in Schedule 2 of
          the <i>Competition and Consumer Act 2010</i> (Cth)) to:
        </Paragraph>
        <ol className="tentacle-terms-roman">
          <li>
            in the case of goods, any one or more of the replacement of the
            goods or the supply of equivalent goods, the repair of the goods,
            the payment of the cost of replacing the goods or acquiring
            equivalent goods or the payment of the cost of having the goods
            repaired; or
          </li>
          <li>
            in the case of services, the supplying of the services again or the
            payment of the cost of having the services supplied again.
          </li>
        </ol>
      </li>
    </ol>

    <Title level={3}>12. Termination and Suspension</Title>
    <ol className="tentacle-terms-abc">
      <li>
        We may, with or without notice to you and at our discretion, limit or
        suspend your right to access or use the Application if we reasonably
        believe you are not complying with this Agreement.
      </li>
      <li>
        Either party may terminate this Agreement on 30 days written notice of
        the other party’s material breach of this Agreement, unless the breach
        is cured during that 30 day period.
      </li>
      <li>
        We may terminate this Agreement immediately if you are subject to a
        change of control or become insolvent.
      </li>
      <li>
        <Paragraph>
          Either party may terminate this Agreement for convenience provided
          that:
        </Paragraph>
        <ol className="tentacle-terms-roman">
          <li>
            where we are the terminating party we will provide you with 30 days
            written notice of termination; and
          </li>
          <li>
            where you are the terminating party, you may elect to terminate the
            Agreement for convenience by following the process outlined in the
            Application or by giving 30 days written notice to Technotia.
          </li>
        </ol>
      </li>
      <li>
        <Paragraph>
          On termination of this Agreement you will cease accessing and using
          the Application and:
        </Paragraph>
        <ol className="tentacle-terms-roman">
          <li>
            pay any outstanding Charges owed to us for Professional Services
            provided up to the effective date of termination;
          </li>
          <li>
            on request, the parties will return any Confidential Information of
            the other party; and
          </li>
          <li>
            At the time of Termination any Customer Data will be erased and no
            longer available to the Customer.
          </li>
        </ol>
      </li>
      <li>
        Termination of this Agreement will not affect the operation of the
        provisions of this Agreement which by their nature survive termination
        or expiry of this Agreement.
      </li>
      <li>
        Termination or expiry of this Agreement will not affect any rights,
        remedies, obligations or liabilities of the parties that have accrued up
        to the date of termination or expiry.
      </li>
    </ol>

    <Title level={3}>13. General</Title>
    <ol className="tentacle-terms-abc">
      <li>
        This Agreement contains the entire agreement between the parties
        concerning the subject matter of this Agreement.
      </li>
      <li>
        A party must do all things and execute all documents that are reasonably
        necessary to give full effect to this Agreement.
      </li>
      <li>
        We will not be in breach of this Agreement or liable for any loss to the
        extent this arises from matters outside of our control.
      </li>
      <li>
        This Agreement and any dispute or claim arising out of or in connection
        with it is governed by and must be construed in accordance with the laws
        in force in New South Wales, Australia and the parties submit to the
        exclusive jurisdiction of the courts of New South Wales.
      </li>
      <li>This Agreement may be signed by counterparts.</li>
      <li>
        You must not assign or novate any rights or entitlements under this
        Agreement without our written consent.
      </li>
      <li>
        Any notice given to a party under or in connection with this Agreement
        must be in writing and must be delivered in accordance with the details
        in the Subscription Order Form.
      </li>
    </ol>

    <Title level={3}>14. Definitions and Interpretation</Title>
    <Title level={4}>14.1 Definitions</Title>
    <Paragraph>
      In this Agreement, the following terms have the meaning set out below.
    </Paragraph>
    <Paragraph>
      <b>Affiliate</b> means in relation to Technotia or Customer, the relevant
      party’s employees and any Related Bodies Corporate.
    </Paragraph>
    <Paragraph>
      <b>Agreement</b> has the meaning given to it in clause 1(a).
    </Paragraph>
    <Paragraph>
      <b>Application</b> means the relevant application(s) identified in the
      Subscription Order Form.
    </Paragraph>
    <Paragraph>
      <b>Application Output</b> means all Material which is produced by the
      Application as a result of this Agreement including by the processing of
      the Customer Data and/or Technotia Data.
    </Paragraph>
    <Paragraph>
      <b>Charges</b> means the Monthly Fee and any other fees set out in the
      Subscription Order Form.
    </Paragraph>
    <Paragraph>
      <b>Commencement Date</b> means the date set out in the Subscription Order
      Form.
    </Paragraph>
    <Paragraph>
      <b>Confidential Information</b> means:
    </Paragraph>
    <ol className="tentacle-terms-abc">
      <li>
        The Application and the Technotia Data, algorithm information and any
        other information that at the time of disclosure by a Disclosing Party
        is identified to the Receiving Party as being confidential or which the
        Receiving Party knows, or ought reasonably to be expected to know, is
        confidential to the Disclosing Party or any Affiliate of the Disclosing
        Party; and
      </li>
      <li>
        All other information belonging or relating to a Disclosing Party, or
        any Affiliate of that Disclosing Party, that is not generally available
        to the public at the time of disclosure other than by reason of a breach
        of this Agreement.
      </li>
    </ol>
    <Paragraph>
      <b>Customer Data</b> means all Material supplied or made available to us,
      by or on behalf of you or your Users in connection with this Agreement.
    </Paragraph>
    <Paragraph>
      <b>Disclosing Party</b> means the party to whom information belongs or
      relates.
    </Paragraph>
    <Paragraph>
      <b>Documentation</b> means information presented through the Application,
      as updated by us from time to time, containing technical specifications
      and other usage requirements, guidance and restrictions which govern your
      use of the Application.
    </Paragraph>
    <Paragraph>
      <b>Intellectual Property Rights</b> means any and all intellectual and
      industrial property rights throughout the world, whether subsisting now or
      in the future, including rights of any kind in:
    </Paragraph>
    <ol className="tentacle-terms-abc">
      <li>
        inventions, discoveries and novel designs, whether or not registered or
        registrable as patents, innovation patents or designs, including
        developments or improvements of equipment, technology, processes,
        methods or techniques;
      </li>
      <li>
        literary works, dramatic works, musical works, artistic works,
        cinematograph films, television broadcasts, sound broadcasts, published
        editions of works and any other subject matter in which copyright
        (including future copyright and rights in the nature of or analogous to
        copyright) may, or may upon creation of the subject matter, subsist
        anywhere in the world;
      </li>
      <li>
        registered and unregistered trade marks and service marks, including
        goodwill in the business concerned in the relevant goods and services;
      </li>
      <li>trade, business or company names;</li>
      <li>internet domain names; and</li>
      <li>
        proprietary rights under the <i>Circuit Layouts Act 1989</i> (Cth),
      </li>
    </ol>
    <Paragraph>
      whether created or in existence before or after the date of this Agreement
      and includes any thing, whether tangible or intangible, which
      incorporates, embodies or is based on any of the things referred to in
      paragraphs (a) to (f) inclusive of this definition.
    </Paragraph>
    <Paragraph>
      <b>O&M Application</b> means Technotia application modules including
      Project, Documents, Chat, Gauge.
    </Paragraph>
    <Paragraph>
      <b>Material</b> means material in whatever form and includes data,
      documents, reports, information, images, content or sounds (together with
      any database made up of any of these), business process and software.
    </Paragraph>
    <Paragraph>
      <b>Fee</b> means the fee outlined in the Subscription Order Form.
    </Paragraph>
    <Paragraph>
      <b>Personal Information</b> has the meaning given in the{' '}
      <i>Privacy Act 1988</i> (Cth) and includes “Sensitive Information” (as
      that term is defined in the <i>Privacy Act 1988</i> (Cth)).
    </Paragraph>
    <Paragraph>
      <b>Privacy Laws</b> means any applicable law, statute, regulation,
      ordinance, code, standard or requirement of any government, governmental
      or semi-governmental body which relates to privacy, including without
      limitation the <i>Privacy Act 1988</i> (Cth) and the Australian Privacy
      Principles under the Privacy Act, and the <i>Spam Act 2003</i> (Cth), as
      amended from time to time.
    </Paragraph>
    <Paragraph>
      <b>Receiving Party</b> means the party to whom information is disclosed or
      who possesses or otherwise acquires information belonging or relating to a
      Disclosing Party
    </Paragraph>
    <Paragraph>
      <b>Related Bodies Corporate</b> has the meaning given to that term in the{' '}
      <i>Corporations Act 2001</i> (Cth).
    </Paragraph>
    <Paragraph>
      <b>Subscription Order Form</b> means the details of your subscription
      arrangements agreed when you accepted the Terms of Use. This includes but
      is not limited to your details, application details, application Terms of
      Use and payment details
    </Paragraph>
    <Paragraph>
      <b>Technotia Data</b> means any data that is provided by us or our
      licensors.
    </Paragraph>
    <Paragraph>
      <b>Term</b> means the term outlined in clause 2.
    </Paragraph>
    <Paragraph>
      <b>User</b> means your employee who is authorised in accordance with the
      terms of this Agreement to access and use the Application.
    </Paragraph>

    <Title level={4}>14.2 Interpretation</Title>
    <Paragraph>
      In this Agreement, unless the context requires otherwise:
    </Paragraph>
    <ol className="tentacle-terms-abc">
      <li>
        the headings are used for convenience only and do not affect the
        interpretation of this Agreement;
      </li>
      <li>
        "include" or any similar expressions must be construed as if it were
        followed by "(without being limited to)"; and
      </li>
      <li>money amounts are in Australian currency.</li>
    </ol>

    <Title level={2}>Schedule 1 - Technotia Data Terms</Title>
    <Title level={3}>1. Definitions</Title>
    <Paragraph>
      <b>Deliverable</b> means the items identified as such in the Subscription
      Order Form or any material that is uniquely created for you in the
      provision of Professional Services.
    </Paragraph>
    <Paragraph>
      <b>Technotia Data Output</b> means the Application Output produced by
      Technotia Data, including Technotia Data Lab, as a result of this
      Agreement including any data that is created by the processing of Customer
      Data and/or Technotia Data.
    </Paragraph>
    <Paragraph>
      <b>Third Party Content</b> means any part of the Technotia Data that is
      provided by our licensors.
    </Paragraph>
    <Paragraph>
      <b>Third Party Terms</b> mean the relevant third party terms are specified
      in an Subscription Order Form.
    </Paragraph>

    <Title level={3}>2. Application</Title>
    <Paragraph>
      These Data terms will apply where the Data Modules, including Technotia
      Data Lab has been selected in the Subscription Order Form
    </Paragraph>

    <Title level={3}>3. Professional Services</Title>
    <ol className="tentacle-terms-abc">
      <li>
        We will provide the service as set out in the Subscription Order Form.
      </li>
      <li>
        We make no warranties in relation to the Deliverables and you agree that
        these are provided on an as-is basis.
      </li>
    </ol>

    <Title level={3}>4. Intellectual Property Rights</Title>
    <ol className="tentacle-terms-abc">
      <li>
        You acknowledge and agree that we and our licensors are the owners of
        and will retain all rights, title and interest in the Technotia Data
        Outputs and Deliverables.
      </li>
      <li>
        To the extent required, you assign all rights, title and interest in the
        Intellectual Property Rights outlined in clause (a) to us, with effect
        from the date of its creation.
      </li>
      <li>
        We grant you a non-exclusive, royalty-free licence to use the Technotia
        Data Outputs and Deliverables during the Term for your own individual
        business purposes.
      </li>
      <li>
        You acknowledge the Technotia Data Outputs may contain Third Party
        Content which may be subject to separate Third Party Terms as outlined
        in the chart view of the Application for all sources. You agree to
        access and use such Third Party Content in accordance with the relevant
        Third Party Terms.
      </li>
      <li>
        You indemnify us against all loss or damage incurred by us in relation
        to a third party claim that arises as a result of your breach of this
        clause 4(d) above.
      </li>
    </ol>

    <Title level={2}>Schedule 2 - Technotia O&M Terms</Title>
    <Title level={3}>1. Definitions</Title>
    <Paragraph>
      <b>Customer IP</b> means any Intellectual Property Rights in the
      Application Outputs that are created as a result of the use of the
      Technotia O&M Application other than Technotia O&M IP and any Intellectual
      Property Rights in Technotia Data Output.
    </Paragraph>
    <Paragraph>
      <b>Technotia O&M IP</b> means any Intellectual Property Rights that are:
    </Paragraph>
    <ol className="tentacle-terms-abc">
      <li>
        owned by or licensed to us prior to the date of this Agreement or are
        developed by us independently of this Agreement (including any
        Intellectual Property Rights in the Technotia O&M Application such as
        any stock photographs, illustrations, fonts, templates and other media);
        and
      </li>
      <li>
        any improvements, enhancements or modifications to the Intellectual
        Property Rights set out in (a) developed by or on behalf of Technotia.
      </li>
    </ol>

    <Title level={3}>2. Application</Title>
    <Paragraph>
      These terms will apply where the Technotia O&M Application has been
      selected in the Subscription Order Form.
    </Paragraph>

    <Title level={3}>3. Intellectual Property Rights</Title>
    <ol className="tentacle-terms-abc">
      <li>
        Technotia O&M IP will remain the property of us and nothing in this
        Agreement will be construed as transferring title in or ownership of any
        Technotia IP to you.
      </li>
      <li>
        We grant to you a non-exclusive, non-transferable, royalty free licence
        to use such components of the Technotia O&M IP that is incorporated in
        the Customer IP to the extent necessary to enable you to fully utilise
        any Customer IP.
      </li>
      <li>
        All Customer IP vests exclusively on its creation in you and you will
        have all right, title and interest to all Customer IP.
      </li>
      <li>
        You grant to us a non-exclusive, non-transferable, royalty free licence
        to use Customer IP for the sole purpose of performing our obligations in
        accordance with this Agreement.
      </li>
      <li>
        To avoid doubt, any Intellectual Property Rights in Technotia Data
        Outputs will be as outlined in the Technotia Data Terms.
      </li>
    </ol>
  </>
);

export const TermsOfUseForm = ({ onTermsRead }) => {
  const scrollDivRef = useRef();
  const scrollDetect = () => {
    if (!scrollDivRef.current) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = scrollDivRef.current;
    const sloshPixels = 12;
    const atBottom =
      Math.abs(scrollTop + clientHeight - scrollHeight) <= sloshPixels;
    if (atBottom) {
      onTermsRead?.(true);
    }
  };
  return (
    <>
      <Paragraph style={{ fontSize: '0.9em' }}>
        Please indicate you have read the{' '}
        <Link href="/legal/terms-of-use" target="_blank">
          Terms of Use
        </Link>{' '}
        by scrolling to the bottom and accepting.
      </Paragraph>
      <div
        style={{
          height: '240px',
          overflow: 'auto',
          margin: '12px 0',
          fontSize: '0.8em',
          padding: '0 16px',
          background: '#eee', // eslint-disable-line
          textAlign: 'left',
        }}
        onScroll={scrollDetect}
        ref={scrollDivRef}
      >
        <TechnotiaTermsOfUse />
      </div>
      <Paragraph style={{ fontSize: '0.9em' }}>
        By clicking "Yes, I Accept" you agree to be bound by the Agreed Terms of
        the Subscription Agreement and agree that predicting the future is an
        inexact science and therefore the results, data and outputs are not
        advice or recommendations, that past performance is not an indicator of
        future performance and that it is your sole responsibility on how you
        interpret and decide to use this information. You should always seek
        independent professional advice, including financial advice that
        considers your individual situation.
      </Paragraph>
    </>
  );
};

const TermsOfUseModal = ({ isOrganisation = false, isPublic = false }) => {
  const [termsRead, setTermsRead] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(true);

  useEffect(() => {
    if (isServerSideRendered) return;
    if (!windowVar?.localStorage?.[termsAcceptedKey]) {
      setTermsAccepted(false);
    }
  }, []);

  // NOTE: Added ternary because `visible` didn't seem to work as expected on
  // first time clicking Ok.
  return termsAccepted ? null : (
    <Modal
      title="Terms of Use"
      centered
      visible={!termsAccepted}
      onOk={() => {
        // TODO: Terms accepted POST request to record.
        // OR: put in the cookie and get upon next request??
        windowVar.localStorage[termsAcceptedKey] = true;
        setTermsAccepted(true);
      }}
      okText="Yes, I Accept"
      closable={false}
      okButtonProps={{
        disabled: !isOrganisation && !termsRead,
      }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      {isOrganisation ? (
        <OrganisationTermsOfUse />
      ) : (
        <TermsOfUseForm onTermsRead={setTermsRead} />
      )}
    </Modal>
  );
};

export default TermsOfUseModal;
